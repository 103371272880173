<template>
<div>
  <div class="push"></div>
  <footer class="footer">
    <div class="container is-max-desktop level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <a href="http://pers-ee.com" target="_blank">
          <img class="logo" src="@/assets/img/logo-persee.svg" alt="logo" /></a>
        </div>
      </div>
      <div class="level-right pl-2 smaller-text">
        <div class="level-item">
          <span>Cookie policy</span>
        </div>
        <div class="level-item">
          <span>Legal statement</span>
        </div>
        <div class="level-item">
          <span>&#169; 2020 Persee</span>
        </div>
      </div>
    </div>
  </footer>
</div>

</template>

<script>
export default {
  name: 'MainFooter',
};
</script>

<style lang="scss" scoped>
.footer {
  padding: 0 !important;

  height: 4rem !important;
}
.level {
  padding: 0 !important;
  z-index: 0;
}
.level,
.level-left,
.level-item,
.level-item .logo {
  height: 100%;
}
.logo {
  display: inline-block;
  width:100px;
}
.smaller-text {
  font-size: 0.8rem;
}
@media screen and (max-width: 1023px) {
  .smaller-text {
    font-size: 0.55rem;
    padding-top: 0.25rem;
  }
}
</style>
