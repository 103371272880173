<template>
  <div class="home">
    <Main />
  </div>
</template>

<script>

import Main from '@/components/Main.vue';

export default {
  name: 'Home',
  components: {
    Main,
  },
  data() {
    return {
      showModal: false,
    };
  },
};

</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
