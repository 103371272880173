<template>
  <div id="app">
    <router-view />
    <MainFooter />
  </div>
</template>
<script>
import MainFooter from './components/MainFooter.vue';

export default {
  name: 'App',
  components: {
    MainFooter,
  },
};
</script>

<style lang="scss">
#app {
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.footer {
  height: 10rem;
}
.clickable {
  cursor: pointer;
}
.container{
  padding: 1rem;
}

</style>
